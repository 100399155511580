import React, {useState, useContext, forwardRef } from 'react';
import {Formik, Field, ErrorMessage, Form} from 'formik'
import * as Yup from 'yup'
import { FirebaseContext } from '../../firebase'
import { StyledForm, StyledButton } from '../../UI/elements'
import Input from '../../UI/Form/input'
import {navigate, Link} from 'gatsby'
import { AuthContext } from '../../firebase'
import PulseLoader from "react-spinners/PulseLoader"
import * as CONST from '../../app/constants/constants'
import { css } from "@emotion/react";


const LoginSchema = Yup.object().shape({
  firstname: Yup.string().required('Veuillez entrer votre prenom'),
  lastname: Yup.string().required('Veuillez entrez votre nom'),
  terms: Yup.boolean().required("Vous devez accepter les termes et conditions").oneOf([true], "Vous devez accepter les termes et conditions"),
  email: Yup.string().email('Email invalide').required('Veuillez entrer votre email'),
  password: Yup.string().required('Veuillez entrer votre mot de passe'),
})


const Register = forwardRef(({isDoctor, isMedPro}, ref) => {

  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const Firebase = useContext(FirebaseContext)
  const {authUser, setAuthUser } = useContext(AuthContext)

  const createUser = async (values) => {
    const {firstname, lastname, email, password} = values
    const isActive = false
    const isCompleted = false
    let response
    if(isDoctor){
      response = await Firebase.registerDoc(firstname, lastname, email, password, isDoctor, isActive, isCompleted)
    }else if(isMedPro){
      response = await Firebase.registerMedPro(firstname, lastname, email, password, isMedPro, isActive, isCompleted)
    }else{
      response = await Firebase.register(firstname, lastname, email, password, isCompleted)
    } 
    if(response.created){   
      navigate(`/verify`, {state:{signin: false}})
    }else{
      setError(response.error)
      setLoading(false)
    }
  }


  return (
    <div className="form-wrapper-signup">
      {
        loading
        ?
          <div className="loader-container">
            <PulseLoader
              css={override}
              size={15}
              color={"#2F80ED"}
              loading={loading}
            />
          </div>
        :
          error
          ?
            <div className="error-container-signup">
              <div className="error-message center">
                <p>
                  Une erreur est survenue:
                </p>
                <p className="message">
                  {CONST.ERRORS[error.code] ? CONST.ERRORS[error.code] : error.message}
                </p>
                <button onClick={() => setError(null)} className="mybtn color-3 color-0-bg">R&eacute;essayer</button>
              </div>            
            </div>
          :
            <Formik
              initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                terms: false
              }}
              validationSchema={LoginSchema}
              onSubmit={(values, actions) =>{
                setLoading(true)
                createUser(values)
                actions.resetForm()
              }}
            >
              {({isSubmitting, isValid, handleReset}) => (
                <Form className="styled-form" ref={ref}>
                  <Field
                    type='text'
                    name='firstname'
                    placeholder='Votre prenom ...'
                    component={Input}
                  />
                  <Field
                    type='text'
                    name='lastname'
                    placeholder='Votre nom ...'
                    component={Input}
                  />
                  <Field
                    type='email'
                    name='email'
                    placeholder='Votre email ...'
                    component={Input}
                  />
                  <Field
                    type='password'
                    name='password'
                    placeholder='Votre password ...'
                    component={Input}
                  />
                  <label className="register-label">
                    <Field type="checkbox" name="terms"/>J'accepte les <Link to="/terms" className="term-link" component={Input}>termes et conditions</Link>
                    <div className="errormessage">
                      <ErrorMessage name="terms"/>
                    </div>
                  </label>
                  {/* {props.close && handleReset} */}
                  <button className="styled-button color-7-bg color-1" type='submit'>Envoyer</button>
                </Form>           
              )}
            </Formik>
      }
    
    </div>
  );
});

export default Register;